type CardProps = {
  title?: string;
  content?: string;
  subTitle?: string;
  subContent?: string;
  image: string;
  imagePosition?: string;
  className: string;
  height?: string;
};

export const Card = ({
  title,
  content,
  subTitle,
  subContent,
  image,
  imagePosition,
  className,
  height,
}: CardProps) => {
  return (
    <div
      className={`flex flex-col gap-[26px] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-500 ${className} ${
        subContent ? 'min-h-0' : ''
      }`}
    >
      <div
        className={`w-full h-full rounded-[18px] bg-[#191932] bg-no-repeat ${
          subContent
            ? 'bg-[length:200px_100px] md:bg-contain bg-right-bottom'
            : ''
        } ${imagePosition ? imagePosition : 'lg:bg-center'}`}
        style={{
          backgroundImage: `url(${image})`,
          height: !subContent && height ? height : '100%',
        }}
      >
        <div className="w-full h-full flex justify-center shrink-0 rounded-[18px] bg-gradient-to-t from-[#191932] to-transparent drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
          <div className="flex items-end pb-[50px] px-[50px]">
            <div className="flex flex-col gap-[15px]">
              {subTitle && (
                <span className="text-white text-[26px] font-bold mt-4">
                  {subTitle}
                </span>
              )}
              {subContent && <span className="text-white">{subContent}</span>}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {title && (
          <span className="text-white text-[26px] font-bold">{title}</span>
        )}
        {content && <span className="text-white/75">{content}</span>}
      </div>
    </div>
  );
};
