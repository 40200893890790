import { Button } from '../widgets/Button';

export const Tokenize = () => {
  return (
    <div className="flex px-10 lg:px-[100px]">
      <div className="w-full">
        <div className="grid md:grid-cols-5 grid-cols-1 md:gap-[149px] gap-4">
          <div className="col-span-2 flex justify-center md:justify-start">
            <span className="text-center text-white text-[46px] font-bold capitalize">
              Join And tokenize
              <br />
              <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
                Our Future
              </span>
            </span>
          </div>
          <div className="col-span-3">
            <div className="flex flex-col gap-10">
              <span className="text-white/75 text-[22px]">
                Shape the future of the protocol by joining Tap Starter
                Community. Tapstarter community participants will be
                incentivised to achieve the best access to Tap Protocol
                Ecosystem Projects
              </span>
              <div className="flex justify-center md:justify-start">
                <a
                  href="https://forms.gle/eZBVHBcEgo9jW5tk9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button title="Pre-Register" className="w-[246px] h-[60px]" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
