import { Button } from '../widgets/Button';

export const Medium = () => {
  return (
    <div className="w-full flex px-10 lg:px-[100px]">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex flex-col gap-[25px]">
          <span className="text-center md:text-left text-white text-[46px] font-bold">
            Medium
          </span>
          <span className="text-white/75 text-[22px]">
            Explore our Medium page for all the latest updates, insights, and
            developments in the Tapstarter universe.
          </span>
        </div>
        <div className="flex justify-center md:justify-end items-end bg-no-repeat bg-right bg-none sm:bg-[url('../public/svg/medium-1.svg')]">
          <a
            href="https://medium.com/@tapstarter.launchpad"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="w-[197px] h-[60px] p-[1.258px]">
              <div className="flex h-full w-full items-center justify-center bg-[#080621] rounded-r-full rounded-l-full">
                <h1 className="text-lg font-medium text-white">
                  Browse all articles
                </h1>
              </div>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
