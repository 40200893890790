import { Card } from '../widgets/Card';

export const Tiered = () => {
  return (
    <div className="flex justify-center px-10 lg:px-[100px]">
      <div className="flex flex-col gap-[60px] justify-center">
        <span className="text-center text-white text-[46px] font-bold capitalize">
          Tiered Access{' '}
          <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
            Ecosystem
          </span>
        </span>
        <div className="grid grid-cols-1 lg:grid-cols-none lg:grid-rows-2 lg:grid-flow-col gap-x-[30px]">
          <Card
            subTitle="Public Tier Access"
            subContent="Open to all, our Public Tier offers unique opportunities with enhanced winning multipliers when using the Tap Bridge, democratizing investment access."
            image="/svg/public-tier.svg"
            className="lg:row-span-2 min-h-[289px] lg:min-h-[616px] lg:hover:scale-105"
          />
          <Card
            subTitle="Private Tier Privileges"
            subContent="Reserved for TAP or Tapstarter token holders, the Private Tier provides exclusive access to special offers, reflecting our commitment to loyal community members."
            image="/svg/private-tier.svg"
            imagePosition="bg-right-bottom"
            className="min-h-[289px] lg:hover:scale-105"
          />
          <Card
            subTitle="Diamonhand Tier"
            subContent="The Diamondhand tier is for the dedicated, offering superior advantages to those holding a certain amount for a specified period, rewarding long-term commitment."
            image="/svg/diamondhand-tier.svg"
            imagePosition="bg-right-bottom"
            className="min-h-[289px] lg:hover:scale-105"
          />
        </div>
      </div>
    </div>
  );
};
