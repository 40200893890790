import { Card } from '../widgets/Card';

export const Impact = () => {
  return (
    <div className="flex justify-center px-10 lg:px-[100px]">
      <div className="flex flex-col gap-[60px] justify-center">
        <span className="text-center text-white text-[46px] font-bold capitalize">
          Amplify Your{' '}
          <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
            Impact
          </span>
        </span>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 justify-between">
          <Card
            title="Incentivized Participation"
            content="Through tier-based benefits and multipliers, encourage active and long-term participation, enhancing the ecosystem's stability and growth."
            image="/svg/participation.svg"
            className=""
            height="311px"
            imagePosition="bg-center"
          />
          <Card
            title="Exclusive Access and Rewards"
            content="Provide distinct advantages to different tiers, catering to a diverse user base from casual participants to committed investors."
            image="/svg/rewards.svg"
            className=""
            height="311px"
            imagePosition="bg-center"
          />
          <Card
            title="Integration with Tap Protocol"
            content="Leverage the Tap Protocol's features for secure, innovative blockchain interactions, positioning TapBAO as a leader in the BRC20 space."
            image="/svg/protocol.svg"
            className=""
            height="311px"
            imagePosition="bg-center"
          />
        </div>
      </div>
    </div>
  );
};
