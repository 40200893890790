export const Footer = () => {
  return (
    <nav>
      <div className="flex w-full items-center justify-between bg-primary px-4 sm:px-10 lg:px-[100px] border-t border-[#35354A] pt-[43px] pb-[57.05px]">
        <div className="flex gap-[11.343px] text-white items-center">
          <img src="/svg/logo.svg" alt="logo" />
          <span className="text-2xl sm:text-[33.273px] font-bold">
            TapStarter
          </span>
        </div>
        <div className="flex justify-between items-center text-white font-medium text-lg gap-4 md:gap-8 lg:gap-24">
          <a
            href="https://twitter.com/TapStarter_LPad"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2.5"
          >
            <img src="/svg/twitter.svg" alt="twitter" />
            <span className="hidden md:flex">Twitter</span>
          </a>
          <a
            href="https://medium.com/@tapstarter.launchpad"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2.5"
          >
            <img src="/svg/medium.svg" alt="medium" />
            <span className="hidden md:flex">Medium</span>
          </a>
          <a
            href="https://t.me/tapstarter_announcements"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2.5"
          >
            <img src="/svg/telegram.svg" alt="telegram" />
            <span className="hidden md:flex">Telegram</span>
          </a>
        </div>
      </div>
    </nav>
  );
};
