import { Impact } from '../landing/Impact';
import { Lanunchpad } from '../landing/Launchpad';
import { Main } from '../landing/Main';
import { Medium } from '../landing/Medium';
import { Tiered } from '../landing/Tiered';
import { Tokenize } from '../landing/Tokenize';
import { Portfolio } from '../landing/Portfolio';

export const Content = () => {
  return (
    <div className="flex flex-col gap-16 sm:gap-24 lg:gap-[160px] bg-primary pb-8 lg:pb-[160px] [background-image:url('../public/svg/left-ellipse.svg'),url('../public/svg/right-ellipse.svg')] [background-position:left,right] [background-repeat:repeat-y,repeat-y]">
      <Main />
      <Impact />
      <Lanunchpad />
      <Tokenize />
      <Tiered />
      <Medium />
      <Portfolio />
    </div>
  );
};
