import { Card } from '../widgets/Card';

export const Portfolio = () => {
  return (
    <div className="flex justify-center px-10 lg:px-[100px]">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 justify-between">
        <Card
          title="Handpicked Ventures"
          content="Each project on Tapstarter is meticulously selected, ensuring only the most promising and innovative ventures are presented to our investors."
          image="/svg/ventures.svg"
          className=""
          height="250px"
          imagePosition="bg-center"
        />
        <Card
          title="Quality Meets Opportunity"
          content="Our platform showcases projects that exemplify quality and potential, offering investors a unique blend of security and opportunity."
          image="/svg/qualify.svg"
          className=""
          height="250px"
          imagePosition="bg-center"
        />
        <Card
          title="Diverse Project Portfolio"
          content="Tapstarter's curated selection spans various sectors, providing investors with a diverse range of high-quality projects to explore and invest in."
          image="/svg/portfolio.svg"
          className=""
          height="250px"
          imagePosition="bg-center"
        />
      </div>
    </div>
  );
};
