import { Button } from '../widgets/Button';
import { useState } from 'react';
import { Accordion } from '../widgets/Accordion';

export const Lanunchpad = () => {
  const [accordions, setAccordion] = useState([
    {
      key: '01',
      title: 'Future-Forward Integration',
      data: `Tapstarter's roadmap integrates liquidity management tools such as liquidity locking and token vesting release.`,
      isOpen: false,
      image: '/svg/launchpad.svg',
      content: [
        {
          name: 'Mechanism Type:',
          value: 'Multi-Tier Staking Program',
        },
        {
          name: 'Eligibility Criteria:',
          value: 'Holders of 10,000+ TAP',
        },
        {
          name: 'Reward Structure:',
          value: 'Higher allocations grant',
        },
        {
          name: 'Distribution Frequency:',
          value: 'Monthly Rewards Distribution',
        },
        {
          name: 'Performance Metrics:',
          value: 'Network contribution multiplier',
        },
      ],
    },
    {
      key: '02',
      title: 'Innovative Reward Mechanics',
      data: `Utilizing Tap Protocol to offer dynamic staking rewards and tier-based incentives that enhance user engagement and contribution.`,
      isOpen: true,
      image: '/svg/launchpad.svg',
      content: [
        {
          name: 'Mechanism Type:',
          value: 'Multi-Tier Staking Program',
        },
        {
          name: 'Eligibility Criteria:',
          value: 'Holders of 10,000+ TAP',
        },
        {
          name: 'Reward Structure:',
          value: 'Higher allocations grant',
        },
        {
          name: 'Distribution Frequency:',
          value: 'Monthly Rewards Distribution',
        },
        {
          name: 'Performance Metrics:',
          value: 'Network contribution multiplier',
        },
      ],
    },
    {
      key: '03',
      title: 'Liquidity Bridge  Integration',
      data: `Powered by Tap Protocol, facilitates seamless asset transfers across chains, boosting liquidity.`,
      isOpen: false,
      image: '/svg/launchpad.svg',
      content: [
        {
          name: 'Mechanism Type:',
          value: 'Multi-Tier Staking Program',
        },
        {
          name: 'Eligibility Criteria:',
          value: 'Holders of 10,000+ TAP',
        },
        {
          name: 'Reward Structure:',
          value: 'Higher allocations grant',
        },
        {
          name: 'Distribution Frequency:',
          value: 'Monthly Rewards Distribution',
        },
        {
          name: 'Performance Metrics:',
          value: 'Network contribution multiplier',
        },
      ],
    },
  ]);

  const toggleAccordion = (accordionkey: string) => {
    const updatedAccordions = accordions.map((accord) => {
      if (accord.key === accordionkey) {
        return { ...accord, isOpen: !accord.isOpen };
      } else {
        return { ...accord, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <div className="flex px-10 lg:px-[100px]">
      <div className="w-full flex flex-col py-20 px-4 md:px-[89px] bg-[#191932] rounded-[20px]">
        <div className="flex justify-center">
          <div className="text-center text-white text-[36px] sm:text-[46px] font-bold capitalize">
            Innovative{' '}
            <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
              Launchpad{' '}
            </span>
            Dynamics
          </div>
        </div>
        <div className="mt-8 border-b border-[#35354A]">
          {accordions.map((accordion) => (
            <Accordion
              key={accordion.key}
              id={accordion.key}
              title={accordion.title}
              data={accordion.data}
              isOpen={accordion.isOpen}
              image={accordion.image}
              content={accordion.content}
              toggleAccordion={() => toggleAccordion(accordion.key)}
            />
          ))}
        </div>
        <div className="flex justify-center md:justify-end mt-[50px]">
          <Button title="View Projects" className="w-[246px] h-[60px]" />
        </div>
      </div>
    </div>
  );
};
