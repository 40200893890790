type ButtonProps = {
  title?: string;
  className?: string;
  children?: JSX.Element;
};

export const Button = ({ title, className, children }: ButtonProps) => {
  return (
    <button
      className={`flex items-center justify-center rounded-r-full rounded-l-full bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 hover:bg-gradient-to-l from-purple-500 via-pink-500 to-yellow-500 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-500 ${className}`}
    >
      {children ? (
        children
      ) : (
        <span className="text-white text-lg font-medium">{title}</span>
      )}
    </button>
  );
};
