import { Button } from '../widgets/Button';

export const Header = () => {
  return (
    <nav>
      <div className="flex w-full justify-between bg-primary px-10 lg:px-[100px] border-b border-[#35354A]">
        <div className="flex gap-[11.34px] text-white my-[34px]">
          <img src="/svg/logo.svg" alt="logo" />
          <span className="text-[33.273px] font-bold font-chakra">
            TapStarter
          </span>
        </div>
        <div className="hidden sm:flex my-[30px]">
          <Button title="Launch App" className="w-[197px] h-[60px]" />
        </div>
      </div>
    </nav>
  );
};
