import { Button } from '../widgets/Button';

export const Main = () => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center text-white bg-[url('../public/svg/main.svg')] bg-center bg-no-repeat">
        <div className="min-h-[732px] max-w-[732px] flex flex-col justify-center px-8 md:px-0">
          <div className="flex justify-center">
            <div className="inline-flex px-5 py-2.5 justify-center items-center rounded-r-full rounded-l-full border-[#CD43FF] border bg-[#FFFFFF12] backdrop-blur-md">
              <span className="text-center text-sm capitalize bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
                Introducing the Tapstarter Launchpad
              </span>
            </div>
          </div>
          <div className="text-center text-white text-5xl md:text-[68px] mt-4 font-semibold capitalize">
            The gateway to New Innovations On Tap
          </div>
          <div className="flex gap-2.5 justify-center items-center pt-8">
            <span className="text-white text-center text-lg opacity-90">
              Be Rewarded For Commitment To TAP Investment Opportunities
            </span>
            <button className="hidden md:flex w-[30px] h-[30px] rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 flex justify-center items-center">
              <img width={12} src="/svg/logo.svg" alt="action button" />
            </button>
          </div>
          <div className="flex flex-wrap gap-5 justify-center py-[34.17px]">
            <a
              href="https://tap-starter.gitbook.io/tap-starter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="w-[197px] h-[60px] p-[1.258px]">
                <div className="flex h-full w-full items-center justify-center bg-gray-800 back rounded-r-full rounded-l-full">
                  <h1 className="text-lg font-medium text-white">Read Docs</h1>
                </div>
              </Button>
            </a>
            <Button title="Launch App" className="w-[197px] h-[60px]" />
          </div>
          <div className="flex flex-wrap gap-6 md:gap-12 justify-center items-center text-white font-medium text-lg">
            <a
              href="https://twitter.com/TapStarter_LPad"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2.5"
            >
              <img src="/svg/twitter.svg" alt="twitter" />
            </a>
            <a
              href="https://medium.com/@tapstarter.launchpad"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2.5"
            >
              <img src="/svg/medium.svg" alt="medium" />
            </a>
            <a
              href="https://t.me/tapstarter_announcements"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2.5"
            >
              <img src="/svg/telegram.svg" alt="telegram" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center px-10 lg:px-[100px]">
        <div className="flex w-full rounded-[19px] bg-[#7C4CEF] bg-[url('../public/svg/mask-group.svg')] bg-cover bg-no-repeat bg-center">
          <div className="flex lg:flex-row flex-col lg:justify-between items-center w-full gap-10 py-[49px] px-4 md:px-[79px]">
            <div className="flex flex-col gap-[15px]">
              <div className="flex flex-col">
                <span className="text-center sm:text-left text-white text-[32px] sm:text-[46px] font-bold capitalize">
                  The Leading Launchpad <br />
                  <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent">
                    on Tap Protocol
                  </span>
                </span>
              </div>
              <span className="text-white/75 text-[22px] max-w-[626px]">
                Seamless project launches with unmatched security and community
                engagement
              </span>
            </div>
            <div className="flex justify-center md:justify-start">
              <a
                href="https://forms.gle/QaqrBsu1H3aF1XMA9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="w-[197px] h-[60px] p-[1.258px]">
                  <div className="flex h-full w-full items-center justify-center bg-[#7C4CEF] back rounded-r-full rounded-l-full">
                    <div className="flex h-full w-full items-center justify-center bg-[#ffffff3d] back rounded-r-full rounded-l-full">
                      <h1 className="text-lg font-medium text-white">
                        Launch Project
                      </h1>
                    </div>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
