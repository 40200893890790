import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';
import { Content } from './components/layout/Content';

function App() {
  return (
    <div className="w-full max-h-screen flex flex-col hide-scrollbar overflow-auto">
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
