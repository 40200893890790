import { CheckCircleIcon } from '@heroicons/react/24/outline';

type AccordionProps = {
  id: string;
  title: string;
  data: string;
  isOpen: boolean;
  image: string;
  content: {
    name: string;
    value: string;
  }[];
  toggleAccordion: () => void;
};

export const Accordion = ({
  id,
  title,
  data,
  isOpen,
  image,
  content,
  toggleAccordion,
}: AccordionProps) => {
  return (
    <div className="border-t border-[#35354A]">
      <div
        className="grid grid-cols-1 md:grid-cols-2 justify-between items-center text-white/75 py-[25px]"
        // onClick={toggleAccordion}
      >
        <div className="flex gap-2 md:gap-[25px]">
          <span
            className={`${
              isOpen
                ? 'bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 bg-clip-text text-transparent'
                : ''
            }`}
          >
            {id}
          </span>
          <span className="text-left">{title}</span>
        </div>
        <span className="text-left text-sm md:text-base mt-2 md:mt-0">
          {data}
        </span>
      </div>
      {isOpen && (
        <div className="flex border-t border-white/50">
          <div className="w-full my-[30px] rounded-[20px] bg-[#222242]">
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div className="flex justify-center items-center bg-[#050520] rounded-[18px]">
                <img src={image} alt={title} />
              </div>
              <div className="flex shrink-0 flex-col items-center py-8 lg:py-[82px] px-[23px] text-white/75">
                {content.map((item, index) => (
                  <div
                    key={index}
                    className={`w-full flex flex-wrap justify-between border-t border-[#ffffff19] py-[15px] ${
                      index === content.length - 1 ? 'border-b' : ''
                    }`}
                  >
                    <div className="flex gap-2.5">
                      <CheckCircleIcon className="w-5 h-5" />
                      <span>{item.name}</span>
                    </div>
                    <span className="text-left lg:text-right">
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
